import React from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import TopSection from '../../components/PrivacyPolicy/index';
import ContactForm from '../../components/PrivacyPolicy/PrivacyPolicy';

import CustomizeSection from '../../components/Home3/CustomizeSection';
import BannerSection from '../../components/Home3/BannerSection';



const PrivaacyPolicyIndex=props=>{

    return(
        <div>
            <BannerSection/>
            <div className='max-md:p-3'>
            <TopSection/>
            <ContactForm/>
            
            </div>
            <Footer/>
        </div>
    )
}
export default PrivaacyPolicyIndex