import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TermandConditions=({data})=>{

    // const data=[
    //     {
    //         heading:`DEPOSITS & TICKETS ARE NOT REFUNDABLE`,
    //         paras:[],
    //         para:``
    //     },
    //     {
    //         heading:`(Terms & Conditions May Apply).`,
    //         paras:[
    //             `Unless Specified, All the deposits paid and tickets purchased / issued are non refundable in case of cancellation or no show . Once flights reserved, bookings / tickets are nonâ€transferable to any other person means that name changes are not permitted. Issued Tickets are also not reâ€routable.`,
    //             `If you are reserving the flight by making the advance partial payment (Initial deposit) then please note that fare/taxes may increase at any time without the prior notice. Its means the price is not guaranteed unless ticket is issued because airline / consolidator has right to increase the price due to any reason. In that case we will not be liable and passenger has to pay the fare/tax difference. We always recommend you to pay ASAP and get issue your ticket to avoid this situation. Further more if you will cancel your reservation due to any reason, then the paid deposit(s) will not be refunded.`
    //         ],
    //         para:``
    //     },
    //     {
    //         heading:`CHECKING ALL FLIGTH DETAILS & PASSENGER NAME(S)`,
    //         paras:[
    //             `It is your responsibility to check all the details are correct i.e. Passenger names (are same as appearing on passport / travel docs), Travelling dates, Transit Time, Origin & Destination, Stop Over, Baggage Allowance and other flight information. Once the ticket is issued then no changes can be made.`
    //         ],
    //         para:``
    //     },
    //     {
    //         heading:`PASSPORT, VISA & IMMIGRATION REQUIREMENTS`,
    //         paras:[
    //             `You are responsible for checking all these items like Passport, Visa (including Transit Visa) and other immigration requirements. You must consult with the relevant Embassy / Consulate, well before the departure time for the up to date information as requirements may change time to time. We cannot accept any liability of any transit visa and if you are refused the entry onto the flight or into any country due to failure on your part to carry the correct passport, visa or other documents required by any airline, authority or country`
    //         ],
    //         para:``
    //     },
    //     {
    //         heading:`RECONFIRMING RETURN/ONWARD FLIGHTS`,
    //         paras:[
    //             `It is your responsibility to RECONFIRM your flights at least 72 hours before your departure time either with your travel agent or the relevant Airline directly. The company will not be liable for any additional costs due to your failure to reconfirm your flights.`
    //         ],
    //         para:``
    //     },
    //     {
    //         heading:`INSURANCE AND BAGGAGE LOSS`,
    //         paras:[`We recommend that you purchase travel insurance. It is your responsibility to ensure you have valid travel insurance that covers your needs and also ensure that you have complied with all the health and vaccination requirements for the countries you are travelling Advice can be obtained from your GP or travel clinic. We don't accept any claim for the lost / Stolen / Damaged Baggage. You have to contact the relevant airline directly in that case.`],
    //         para:``
    //     },
    //     {
    //         heading:`SPECIAL REQUESTS AND MEDICAL PROBLEMS`,
    //         paras:[
    //             `If you have any special requests like meal preference, Seat Allocation and wheel chair request etc, please advise us at time of issuance of ticket. We will try our best to fulfill these by passing this request to relevant airline but we cannot guarantee and failure to meet any special request will not held us liable for any claim.`
    //         ],
    //         para:``
    //     },
    //     {
    //         heading:`CHANGING YOUR ARRANGEMENTS`,
    //         paras:[
    //             `If you wish to change any item – other than increasing the number of persons in your party – and providing we can accommodate the change, you will have to pay an Amendment Fee per person. These fees can vary greatly and will be advised at the time changes are made. Changes must be confirmed to us in writing. From time to time we are required to collect additional taxes and surcharges.`
    //         ],
    //         para:` `
    //     }
    // ]

    return(

        <div className='relative mt-3  p-10'>
            {
                data.map(x=>{
                    return <div className="my-6">
                         <p className=' text-2xl tracking-[1px] leading-10'>
                    {x.heading}
                        </p>
                        {
                            x.paras.map(e=>{
                                console.log(e);
                                return  <p className=' text-md mb-3 tracking-[2px] leading-7'>
                                {e}
                               </p>
                            })
                        }
                       
                    </div>
                })
            }
	
</div>
    )
}
export default TermandConditions