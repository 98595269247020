import App from "../App";
import { appConfig } from "../appConfig";



import Contact from "../views/Contact";

import Home3 from "../components/Home3/Topsection";

import PackageDetail from "../components/Packagedetail/Packagedetail";
import Aboutus from "../views/Aboutus";
import CookiePolicy from "../views/CookiePolicy/index";
import PaymentSecurity from "../views/PaymentSecurity/index";
import PrivacyPolicy from "../views/PrivacyPolicy/index";
import TC from "../views/TermsAndCondition/index";
import UmrahVisa from "../views/UmrahVisa/index";
import AllPackages from "../components/AllPackages/AllPackages";
import FlightsDetail from "../components/FlightsDetails/index";

const data=[
    {
        heading:`DEPOSITS & TICKETS ARE NOT REFUNDABLE`,
        paras:[],
        para:``
    },
    {
        heading:`(Terms & Conditions May Apply).`,
        paras:[
            `Unless Specified, All the deposits paid and tickets purchased / issued are non refundable in case of cancellation or no show . Once flights reserved, bookings / tickets are nonâ€transferable to any other person means that name changes are not permitted. Issued Tickets are also not reâ€routable.`,
            `If you are reserving the flight by making the advance partial payment (Initial deposit) then please note that fare/taxes may increase at any time without the prior notice. Its means the price is not guaranteed unless ticket is issued because airline / consolidator has right to increase the price due to any reason. In that case we will not be liable and passenger has to pay the fare/tax difference. We always recommend you to pay ASAP and get issue your ticket to avoid this situation. Further more if you will cancel your reservation due to any reason, then the paid deposit(s) will not be refunded.`
        ],
        para:``
    },
    {
        heading:`CHECKING ALL FLIGTH DETAILS & PASSENGER NAME(S)`,
        paras:[
            `It is your responsibility to check all the details are correct i.e. Passenger names (are same as appearing on passport / travel docs), Travelling dates, Transit Time, Origin & Destination, Stop Over, Baggage Allowance and other flight information. Once the ticket is issued then no changes can be made.`
        ],
        para:``
    },
    {
        heading:`PASSPORT, VISA & IMMIGRATION REQUIREMENTS`,
        paras:[
            `You are responsible for checking all these items like Passport, Visa (including Transit Visa) and other immigration requirements. You must consult with the relevant Embassy / Consulate, well before the departure time for the up to date information as requirements may change time to time. We cannot accept any liability of any transit visa and if you are refused the entry onto the flight or into any country due to failure on your part to carry the correct passport, visa or other documents required by any airline, authority or country`
        ],
        para:``
    },
    {
        heading:`RECONFIRMING RETURN/ONWARD FLIGHTS`,
        paras:[
            `It is your responsibility to RECONFIRM your flights at least 72 hours before your departure time either with your travel agent or the relevant Airline directly. The company will not be liable for any additional costs due to your failure to reconfirm your flights.`
        ],
        para:``
    },
    {
        heading:`INSURANCE AND BAGGAGE LOSS`,
        paras:[`We recommend that you purchase travel insurance. It is your responsibility to ensure you have valid travel insurance that covers your needs and also ensure that you have complied with all the health and vaccination requirements for the countries you are travelling Advice can be obtained from your GP or travel clinic. We don't accept any claim for the lost / Stolen / Damaged Baggage. You have to contact the relevant airline directly in that case.`],
        para:``
    },
    {
        heading:`SPECIAL REQUESTS AND MEDICAL PROBLEMS`,
        paras:[
            `If you have any special requests like meal preference, Seat Allocation and wheel chair request etc, please advise us at time of issuance of ticket. We will try our best to fulfill these by passing this request to relevant airline but we cannot guarantee and failure to meet any special request will not held us liable for any claim.`
        ],
        para:``
    },
    {
        heading:`CHANGING YOUR ARRANGEMENTS`,
        paras:[
            `If you wish to change any item – other than increasing the number of persons in your party – and providing we can accommodate the change, you will have to pay an Amendment Fee per person. These fees can vary greatly and will be advised at the time changes are made. Changes must be confirmed to us in writing. From time to time we are required to collect additional taxes and surcharges.`
        ],
        para:` `
    }
]


const dataTravel=[
    {
        heading:`Travel Tips`,
        paras:[
            `1) Always book your Flight Earlier especially for high seasons (for summer holidays, for Christmas and New Year Holidays, and for ester holidays).`,
            `2) Always match your name with the Passport at the time of booking or at the time of issuance of the ticket just to avoid any kind of inconvenience at the airport.`,
            `3) Always check the validity of your passport and visa before finalizing your travel trip and do not forget to check the requirements of the Country/city you are visiting.`,
            `4) Keep your Visa and ticket with you at the time of travel.`,
            `5) Always confirm your luggage with the Airline with which you are travelling before the travel just to avoid hustle at the Airport.`,
            `6) Make sure you are taking right amount of luggage with you, which you have allowed by the Airline. Never exceed your bag.`,
            `7) Always try to reach at the Airport on time at least 4 to 5 hours before the flight so you will not miss, you flight.`,
            `8) If you need any extra service then give notice to airlines well before travel time for special meal requirements, seating allocation requests and wheel chair arrangements etc.`,
            `9) Do not drink too much before boarding the aircraft, as alcohol and cabin pressure because enhanced dehydration. You are also unable to use the toilets until the aircraft is airborne and at a safe altitude for the seat belt sign to be taken off.`,
            `10) Try to do online checking 24 hours before the flight by going on the Airline Website to avoid rush at the last moment.`,
            `11) If you need extra baggage, do confirm with charges with the Airline well before the time.`,
            `12) Do not forget to check health requirements for all the countries you are visiting on your journey. Please be aware some vaccinations need to be administered 4-6 weeks before travel.`
        ],
        para:``
    },
    
]


const dataAboutUs=[
    {
        heading:``,
        paras:[
            `eDreamSales UK operates one of the United Kingdom's most popular and most trusted travel agencies. With the backing of advantageous partnerships with all global-airline-alliance's member carriers, and a vast network of hotels and tour operators etc. it has been established with the primary goal to provide a one-stop travel shop for business and leisure clients to pick from top quality travel solutions tailored to best suit their unique requirements. eDreamSales UK FARES combines the advantage of industry expertise that has been built over three decades with a brand new customer service goal and is committed to offer value for money proposition for your air travel and holidays to worldwide destinations.`
        ],
        para:``
    },
    {
        heading:`What We Offer`,
        paras:[
            `Call us for anything and everything that global travel entails! We specialize in organizing flights and tailor leisure and business trips, short-breaks and well-earned holidays for all popular destinations across the world. Be it the late last minute business flight or a well-planned holiday package, a long awaited family getaway or a wedding or honeymoon package deal, a multi-center flight ticket or round the world holiday tour, a solo backpacking holiday or group travel, you can rely on our expertise no matter your choices.`
        ],
        para:``
    },
    {
        heading:`Why Call Us!`,
        paras:[
            `And when you contact eDreamSales UK , you will find that we connect with the emotional drivers behind every contact and appreciate the need for our clients to receive expert and honest travel advice as well as the best value for money. No matter your choice of destination, airline or hotel, taste or budget, you can expect the best product and service time after time. By investing heavily both in our technology and our resources, we endeavor to make your holiday experience utterly enjoyable - right from the time you book with us through the destination, and until you arrive back home!`
        ],
        para:``
    },
    {
        heading:`Financial Protection`,
        paras:[
            `We are honored to have a Proven and Established Track Record. Serving since many years and not only have thousands of clients but retaining and growing. Proudly speaking we have 70 % level of repeat customers. For details see our Testimonial Page.`
        ],
        para:``
    },
    {
        heading:`Secure Transactions & Complete Privacy`,
        paras:[
            `Your Credit/Debit card information is always safe when you book with us. We also understand and respect the importance of your privacy. Your personal information is private and confidential and not released to third parties. View our privacy policy.`
        ],
        para:``
    },
    {
        heading:`Dedicated Customer Support`,
        paras:[
            `We have a professional and friendly staff that is highly motivated & skilled to help you with all the questions you might have. We are available 24 hours a day, 7 days a week.`
        ],
        para:``
    },
    
]

const headRoute = appConfig.headRoute

export const routes=[

    {
        path:`${headRoute}/`,
        element:<Home3/>,
    },
    {
        path:`${headRoute}/flight-detail`,
        element:<FlightsDetail/>,
    },

    // {
    //     path:`${headRoute}/contact`,
    //     element:<Contact/>,
    // },
    // {
    //     path:`${headRoute}/aboutus`,
    //     element:<Aboutus/>,
    // },
    // {
    //     path:`${headRoute}/home3`,
    //     element:<Home3/>,
    // },
    // {
    //     path:`${headRoute}/packagedetail`,
    //     element:<PackageDetail/>,
    // },
    // {
    //     path:`${headRoute}/cookiepolicy`,
    //     element:<CookiePolicy/>,
    // },
    {
        path:`${headRoute}/privacypolicy`,
        element:<PrivacyPolicy/>,
    },
    // {
    //     path:`${headRoute}/paymentsecurity`,
    //     element:<PaymentSecurity/>,
    // },
    {
        path:`${headRoute}/tc`,
        element:<TC data={data} headng={`Term And Conditions`}/>,
    },
    {
        path:`${headRoute}/traveltips`,
        element:<TC data={dataTravel} headng={`Travel Tips`}/>,
    },
    {
        path:`${headRoute}/aboutus`,
        element:<TC data={dataAboutUs} headng={`About Us`}/>,
    },
    // {
    //     path:`${headRoute}/umrahvisa`,
    //     element:<UmrahVisa/>,
    // },
    // {
    //     path:`${headRoute}/allpackages`,
    //     element:<AllPackages/>,
    // },
    
]