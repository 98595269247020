import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const TopSection=({headng})=>{

    return(

        <div className='relative mt-[100px] h-[35vh] flex  justify-center items-center bg-primary'>
            <div className="">
            <p className="text-5xl text-center mb-3">
                         {headng}
                     </p>
                
            </div>
	
</div>
    )
}
export default TopSection