import React, { useEffect, useState } from "react";



import AOS from "aos";
import "aos/dist/aos.css";
import service from "../../Service/Service";
import Footer from "../footer";

import BannerSection from "../Home3/BannerSection";
import { useSearchParams } from "react-router-dom";
import { Loader } from "react-feather";
import { Rocket } from "lucide-react";
import parse from 'html-react-parser';
import InputSection from "../Home3/InputSection";

const FlightDetail = (props) => {
  const [flights, setFlights] = useState([]);
  const [packagee, setPackage] = useState();
  const [packageData, setPackageData] = useState();
  const [loading, setLoading] = useState(false);
  const [paras, setParas] = useState([]);


  const [searchParams, setSearchParams] = useSearchParams();
 
  
    useEffect(()=>{
      
      const token = searchParams.get('package')
  console.log(token)//123
  if(!token){
    localStorage.clear();
    window.location.href='/'
    return;
  }

  
  setPackage(token)
  
    },[window.location])

    const getAllparas = (id) => {
        service.getAllparas().then((response) => {
          let dep = response.data.filter((x) => x.package_id == id);
          setParas(dep)
         
        });
      };

    useEffect(()=>{
      
    if(packagee){
       let pp={}
        if(packagee==0){
          let params=localStorage.getItem('booking');
          if(params){
            params=JSON.parse(params);

            console.log(params,'params')
            pp=params;
          }
        }
        setLoading(true)
        service.postGetFlights({
            package:packagee,
            ...pp
        }).then(ress=>{
            setLoading(false)
            console.log(ress.data.flights);
            setFlights(ress.data.flights)
            setPackageData(ress.data.package)
            getAllparas(ress.data.package.uid)
        }).catch(err=>{
            setLoading(false)
        })
    }
  
    },[packagee])
 

  return (
    loading?<div className="absolute z-[4] bg-[rgba(0,0,0,0.9)] w-full h-[100vh] flex justify-center items-center gap-3">
    <Rocket className="text-white text-lg"/> <p className="text-white text-lg">Loading...</p>
</div>:
    <div className="relative">
        

      <BannerSection/>
      <div className=" flex justify-center items-center">
        <p className="text-[40px] my-2 font-bold">Flights Detail</p>
      </div>
      

    {
         flights ? flights.map(f=>{
return(<div className="grid grid-cols-2 md:grid-cols-4 p-4 border border-mLightGray rounded-lg shadow-lg my-2 w-[90%] md:w-[80%] mx-auto">
    <div className="flex gap-2 items-center">
        <p className="font-bold text-lg">{packageData.dep_iatacode}</p>
        <p className=" text-lg">to</p>
        <p className="font-bold text-lg">{packageData.arr_iatacode}</p>
    </div>
    <div className="flex flex-col justify-center items-center">
    <p className="font-bold">Price</p>
        <p>{f.price.grandTotal} ({f.price.currency})</p>
    </div>
    <div className="flex flex-col justify-center items-center">
        <p className="font-bold">Last Ticketing Date</p>
        <p>{f.lastTicketingDate}</p>
    </div>
    <div  className="flex justify-end items-center">

        <button className="btn rounded-xl bg-primary " onClick={()=>{
            document.getElementById('my_modal_flightdetail').showModal()
        }}>Book now</button>
    </div>
    <div className="col-span-full w-full">
        <hr className="text-mLightGray mt-1"/>
        <div className="flex flex-col md:flex-row w-full">
            {
                f.itineraries[0].segments.map((s,index)=>{
                    return <div className="w-full md:w-[50%]">
                        <p className="text-mLightGray font-bold">Sengment:{index+1}</p>
                        <div className="grid grid-cols-4 gap-2">
                        <p className="text-mLightGray">Arrival: </p>
                        <p>{s.arrival.iataCode} - T:{s.arrival.terminal}</p>
                        <p className="text-mLightGray">Departure: </p>
                        <p>{s.departure.iataCode} - T:{s.departure.terminal}</p>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
</div>)
        }):<p className="text-lg text-mLightGray">No Flights</p>
    }


<div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1">
        {paras.map((x) => {
          return parse(x.description)
        })}
      </div>
      
      <Footer />

      <dialog id="my_modal_flightdetail" className="modal">
  <div className="modal-box w-11/12 max-w-5xl bg-[#22c55e]">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" onClick={()=>{window.location.href='/'}}>✕</button>
    </form>
    <h4 className="text-white">Booking has been submitted, one of our agents will call you shortly.
    For quick response, please call: (020 4548 3242)</h4>
  </div>
</dialog>
    </div>
  );
};



export default FlightDetail;
